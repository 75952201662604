import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage, warningMessage } from '../../utils/errorMessages';

const salesforceContacts = Repository.service('salesforceContacts');

export default {
  namespaced: true,
  state: {
    getAllContacts: null,
    originSelectedContact: null,
    deliverySelectedContact: null,
  },
  getters: {
    showAllContacts(state) {
      return state.getAllContacts;
    },
    getOriginSelectedContact(state) {
      return state.originSelectedContact;
    },
    getDeliverySelectedContact(state) {
      return state.deliverySelectedContact;
    },
  },
  mutations: {
    getAllContacts(state, newAllContacts) {
      state.getAllContacts = newAllContacts;
    },
    setOriginSelectedContact(state, newSelectedContact) {
      state.originSelectedContact = newSelectedContact;
    },
    setDeliverySelectedContact(state, newSelectedContact) {
      state.deliverySelectedContact = newSelectedContact;
    },
  },
  actions: {
    async createContact({ dispatch }, payload) {
      try {
        const response = await salesforceContacts.createContact(payload);
        return response;
      } catch (error) {
        if (error.response.status === 422 || error.response.status === 400) {
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
    async getContactbyCustomer({ commit, dispatch }, data) {
      try {
        const response = await salesforceContacts.getContactbyCustomer(data);
        commit('getAllContacts', response.data);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
  },
};
