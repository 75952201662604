import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Element
import '@/plugins/element';
// eCharts
import '@/plugins/eCharts';
/* // Vue Leaflet
import '@/plugins/vueLeaflet'; */

// BootstrapVue
import './plugins/bootstrapVue';

// PrimeVue
import './plugins/primeVue';

// sweetalert
import './plugins/sweetalert';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
