import api from '../clients/axios';

const resource = 'api/v1/booking';

export default {
  async getBookingInfo(token) {
    const params = {};
    params.token = token;
    const response = await api.get(`${resource}`, { params });
    return response.data;
  },
  async sendBookingInfo(bookingInfo) {
    const apiKeyParam = {
      'api-key': bookingInfo.api_key,
    };
    const response = await api.post(`${resource}`, bookingInfo.bookingSchema, { params: apiKeyParam });
    return response;
  },
  async sendBookingMagaya(bookingInfo) {
    const apiKeyParam = {
      'api-key': bookingInfo.api_key,
    };
    const response = await api.post(`${resource}/magaya`, bookingInfo.bookingSchema, { params: apiKeyParam });
    return response;
  },
};
