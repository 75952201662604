import Vue from 'vue';
import Vuex from 'vuex';
import booking from './modules/Booking';
import salesforceAccounts from './modules/salesforceAccounts';
import salesforceContacts from './modules/salesforceContacts';
import lineItems from './modules/lineItems';
import refreshToken from './modules/refreshToken';
import informationByZone from './modules/informationByZone';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    booking,
    salesforceAccounts,
    salesforceContacts,
    lineItems,
    refreshToken,
    informationByZone,
  },
});
