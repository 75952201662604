const BookingRoute = [
  {
    path: '/booking/:token',
    name: 'Booking',
    props: true,
    component: () => import('../../views/Booking/Booking.vue'),
  },
  {
    path: '/booking-magaya/:token',
    name: 'BookingMagaya',
    props: true,
    component: () => import('../../views/Booking/BookingMagaya.vue'),
  },
  {
    path: '/loadBook',
    name: 'loadBook',
    component: () => import(/* webpackChunkName: "loadBook" */ '../../views/LoadBook/LoadBook.vue'),
  },
];

export default BookingRoute;
