import api from '../clients/axios';

const resource = 'api/v1/line-items';

export default {
  async getPicklistsHazmat(data) {
    const response = await api.get(`${resource}/picklists`, { params: data });
    return response;
  },
};
