import api from '../clients/axios';

const resource = 'api/v1/refresh-token';

export default {
  async getRefreshToken(token) {
    const queryToken = {
      token,
    };
    const response = await api.post(`${resource}`, null, { params: queryToken });
    return response;
  },
};
