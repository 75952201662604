/* import { Message } from 'element-ui'; */
import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage, warningMessage } from '../../utils/errorMessages';
import router from '../../router';

const booking = Repository.service('booking');

export default {
  namespaced: true,
  state: {
    bookingInfo: null,
    sendBooking: {},
    originExpectedDate: null,
    hazmatInfo: [],
    hazmatItems: null,
  },
  getters: {
    showBookingInfo(state) {
      return state.bookingInfo;
    },
    showInfoSendBooking(state) {
      return state.sendBooking;
    },
    showOriginExpectedDate(state) {
      return state.originExpectedDate;
    },
    showHazmatInfo(state) {
      return state.hazmatItems;
    },
  },
  mutations: {
    setBookingInfo(state, newBookingInfo) {
      state.bookingInfo = newBookingInfo;
    },
    setSendBooking(state, infoSendBooking) {
      state.sendBooking[infoSendBooking.propName] = infoSendBooking.info;
    },
    setOriginExpectedDate(state, newOriginExpectedDate) {
      state.originExpectedDate = newOriginExpectedDate;
    },
    setHazmatInfo(state, newHazmatInfo) {
      state.hazmatInfo[newHazmatInfo.modalIndex] = { ...newHazmatInfo.info };
      if (!newHazmatInfo.info) {
        state.hazmatInfo[newHazmatInfo.modalIndex] = null;
      }
      state.hazmatItems = [...state.hazmatInfo];
    },
  },
  actions: {
    async getBookingInfo({ commit }, token) {
      try {
        const response = await booking.getBookingInfo(token);
        commit('setBookingInfo', response);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail[0].msg);
        }
        if (error.response.status === 400) {
          errorValidationMessage(error.response.data.detail);
          if (
            error.response.data.detail === 'The load was booked, please, check other load'
            || error.response.data.detail
              === 'The load is in process tender, check it when process finished and received email'
          ) {
            router.push({
              name: 'loadBook',
            });
          }
          return error.response;
        }
        if (error.response.status === 401 || error.response.status === 403) {
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return console.error(error);
      }
    },
    async sendBookingInfo({ dispatch }, bookingInfo) {
      try {
        const response = await booking.sendBookingInfo(bookingInfo);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail[0].msg);
        }
        if (error.response.status === 400) {
          if (error.response.data.detail.includes('Dear Customer, Account is exceeding credit limit')) {
            return warningMessage(error.response.data.detail);
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return console.error(error);
      }
    },
    async sendBookingMagaya({ dispatch }, bookingInfo) {
      try {
        const response = await booking.sendBookingMagaya(bookingInfo);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail[0].msg);
        }
        if (error.response.status === 400) {
          if (error.response.data.detail.includes('Dear Customer, Account is exceeding credit limit')) {
            return warningMessage(error.response.data.detail);
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return console.error(error);
      }
    },
  },
};
