import Vue from 'vue';
import VueRouter from 'vue-router';
import BookingRoute from './modules/booking';

Vue.use(VueRouter);

// Preguntar/Borrar
const AppRoute = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "pagenotfound" */ '../views/PageNotFound.vue'),
    beforeEnter: async (to, from, next) => {
      const url = to.fullPath;
      if (url.includes('booking-magaya')) {
        const bookingIndex = url.indexOf('booking-magaya/') + 'booking-magaya/'.length;
        next(`/booking-magaya/${encodeURIComponent(url.slice(bookingIndex))}`);
        return;
      }
      if (url.includes('booking')) {
        const bookingIndex = url.indexOf('booking/') + 'booking/'.length;
        next(`/booking/${encodeURIComponent(url.slice(bookingIndex))}`);
        return;
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...BookingRoute,
    ...AppRoute,
  ],
});

export default router;
