import api from '../clients/axios';

const resource = 'api/v1/';

export default {
  async getTimeZones(params) {
    const response = await api.get(`${resource}timezones/city`, { params });
    return response;
  },
  async getCitiesByZip(params) {
    const payload = {
      'api-key': params['api-key'],
    };
    const response = await api.get(`${resource}locations/zipcode/${params.zip_code}/cities`, { params: payload });
    return response;
  },
};
