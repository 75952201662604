import api from '../clients/axios';

const resource = 'api/v1/salesforce-contacts';

export default {
  async createContact(body) {
    const response = await api.post(`${resource}`, body.body, { params: body.params });
    return response;
  },
  async getContactbyCustomer(data) {
    const response = await api.get(`${resource}/customer/${data.customerId}`, { params: data.params });
    return response;
  },
};
