import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage, warningMessage } from '../../utils/errorMessages';

const informationByZone = Repository.service('informationByZone');

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getTimeZones({ dispatch }, params) {
      try {
        const response = await informationByZone.getTimeZones(params);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
    async getCitiesByZip({ dispatch }, params) {
      try {
        const response = await informationByZone.getCitiesByZip(params);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
  },
};
