import Booking from './Booking';
import SalesforceAccounts from './SalesforceAccounts';
import SalesforceContacts from './SalesforceContacts';
import LineItems from './LineItems';
import RefreshToken from './RefreshToken';
import InformationByZone from './InformationByZone';

const repositories = {
  booking: Booking,
  salesforceAccounts: SalesforceAccounts,
  salesforceContacts: SalesforceContacts,
  lineItems: LineItems,
  refreshToken: RefreshToken,
  informationByZone: InformationByZone,
};

export default {
  service: (name) => repositories[name],
};
