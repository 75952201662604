import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage } from '../../utils/errorMessages';
import router from '../../router';

const refreshToken = Repository.service('refreshToken');

export default {
  namespaced: true,
  state: {
    refreshCounter: 0,
  },
  getters: {},
  mutations: {
    setRefreshCounter(state) {
      state.refreshCounter += 1;
    },
  },
  actions: {
    async getRefreshToken({ rootGetters, state, commit }) {
      commit('setRefreshCounter');
      try {
        const apiKey = rootGetters['booking/showBookingInfo'].api_key;
        let response;
        if (state.refreshCounter === 1) {
          response = await refreshToken.getRefreshToken(apiKey);
          if (response.status === 200) {
            router.go();
          }
        }
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail[0].msg);
        }
        if (error.response.status === 401) {
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
  },
};
