import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage, warningMessage } from '../../utils/errorMessages';

const salesforceAccounts = Repository.service('salesforceAccounts');

export default {
  namespaced: true,
  state: {
    allAccounts: null,
    originSelectedAccount: null,
    deliverySelectedAccount: null,
  },
  getters: {
    getAllAccounts(state) {
      return state.allAccounts;
    },
    getOriginSelectedAccount(state) {
      return state.originSelectedAccount;
    },
    getDeliverySelectedAccount(state) {
      return state.deliverySelectedAccount;
    },
  },
  mutations: {
    setAllAccounts(state, newAllAccounts) {
      state.allAccounts = newAllAccounts;
    },
    setOriginSelectedAccount(state, newSelectedAccount) {
      state.originSelectedAccount = newSelectedAccount;
    },
    setDeliverySelectedAccount(state, newSelectedAccount) {
      state.deliverySelectedAccount = newSelectedAccount;
    },
  },
  actions: {
    async getAllAccounts({ commit, dispatch }, data) {
      try {
        const response = await salesforceAccounts.getAllAccounts(data);
        commit('setAllAccounts', response);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        }
        if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
    async createAccount({ dispatch }, payload) {
      try {
        const response = await salesforceAccounts.createAccount(payload);
        return response;
      } catch (error) {
        if (error.response.status === 422 || error.response.status === 400) {
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
  },
};
