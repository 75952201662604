import Repository from '../../services/repositories/RepositoryFactory';
import { errorMessage, errorValidationMessage, warningMessage } from '../../utils/errorMessages';

const lineItems = Repository.service('lineItems');

export default {
  namespaced: true,
  state: {
    infoPicklistsHazmat: null,
  },
  getters: {
    showPicklistsHazmat(state) {
      return state.infoPicklistsHazmat;
    },
  },
  mutations: {
    setPicklistsHazmat(state, newPicklistsHazmat) {
      state.infoPicklistsHazmat = newPicklistsHazmat;
    },
  },
  actions: {
    async getPicklistsHazmat({ commit, dispatch }, data) {
      try {
        const response = await lineItems.getPicklistsHazmat(data);
        commit('setPicklistsHazmat', response.data);
        return response;
      } catch (error) {
        if (error.response.status === 422) {
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status === 401 || error.response.status === 403) {
          if (
            error.response.data.detail === 'API key not valid'
            || error.response.data.detail === 'API key not valid or expired'
            || error.response.data.detail === 'An API key must be passed as query or header'
          ) {
            warningMessage('The page will reload and you will be able to continue the process.');
            setTimeout(() => {
              dispatch('refreshToken/getRefreshToken', '', { root: true });
            }, 5000);
            return error;
          }
          return errorValidationMessage(error.response.data.detail);
        } if (error.response.status !== 200) {
          return errorMessage();
        }
        return error;
      }
    },
  },
};
