import swal from 'sweetalert';

export function errorMessage() {
  swal({
    title: 'There is something wrong',
    text: 'Please, try again.',
    icon: 'error',
    dangerMode: true,
  });
}

export function errorValidationMessage(text) {
  swal({
    title: 'Validation Error',
    text: `${text}`,
    icon: 'error',
    dangerMode: true,
  });
}

export function warningMessage(text) {
  swal({
    title: 'Please wait!',
    text: `${text}`,
    icon: 'warning',
    dangerMode: true,
  });
}

export function successMessage(text, time) {
  swal({
    title: 'Good job!',
    text: `${text}`,
    icon: 'success',
    buttons: false,
    dangerMode: false,
    timer: `${time}`,
  });
}

export function bookMessage() {
  swal({
    title: 'Your load was successfully booked!',
    text: `Please send a copy of the BOL and Labels to the shipper once you receive them by email. 
    
    If you don’t receive any email including a link to download the documents, please reply back to the email quote asking for our Operations supports.`,
    icon: 'success',
    button: 'Accept',
  });
}
